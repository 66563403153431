import * as actionTypes from "../constants/history"

const setHistoryWingo = (data) => ({
    type: actionTypes.HISTORY_WINGO,
    data: data
})
const setBetHistory = (data) => ({
    type: actionTypes.BET_HISTORY,
    data: data
})

export {
    setHistoryWingo,
    setBetHistory
}