
import { handleGetAppConfigurationSuccess } from "actions/appAction"
import ForgotPass from "Page/ForgotPass"
import { Introduce } from "Page/Introduce"
import DetailBranch from "Page/Organization/detailBranch"
import HistoryPromotion from "Page/Promotions/HistoryPromotion"
import MyTeam from "Page/Promotions/MyTeam"
import Promotion from "Page/Promotions/Promotions"
import Tuturial from "Page/Promotions/Tuturial"
import Saving from "Page/Saving"
import Settings from "Page/Settings"
import TransactionHistory from "Page/TransactionHistory"
import React, { lazy, Suspense, useEffect } from "react"
import { FormattedMessage } from "react-intl"
import {  useSelector } from 'react-redux'
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import Layout from './../src/Page/Layout'
import { IconBranch, IconLeaderBoard, IconNotification, IconOrganizationSmall, IconPacket, IconProfileIcon, IconRechargeHistory, IconSaving, IconSettings, IconSignout, IconSupport, IconWallet, ThunderButton2 } from "./assets/icons/index"
const NotificationDetail = lazy(() => import('./../src/Page/Notifications/Detail'))
const Home = lazy(() => import("./../src/Page/Home"));
const Register = lazy(() => import("./../src/Page/Register"));
const Login = lazy(() => import("./../src/Page/Login"));
const Game5DLotre = lazy(() => import('./Page/Game5DLotre'));
const AllBetWingo = lazy(() => import('./Page/GameWinGo/allBetWingo'));
const AllBetK3Lotre = lazy(() => import('./Page/GameK3Lotre/allBetK3Lotre'));
const AllBet5DLotre = lazy(() => import('./Page/Game5DLotre/allBet5DLotre'));
const BoughtMachine = lazy(() => import('./../src/Page/TransactionHistory/BoughtMachine'));
const DepositUSDT = lazy(() => import('./../src/Page/TransactionHistory/DepositUSDT'));
const ExchangeFAC = lazy(() => import('./../src/Page/TransactionHistory/ExchangeFAC'));
const ExchangePoint = lazy(() => import('./../src/Page/TransactionHistory/ExchangePoint'));
const ReceivePoint = lazy(() => import('./../src/Page/TransactionHistory/ReceivePoint'));
const ReceiveBTC = lazy(() => import('./../src/Page/TransactionHistory/ReceiveBTC'));
const ReceiveFAC = lazy(() => import('./../src/Page/TransactionHistory/ReceiveFAC'));
const WithdrawBTC = lazy(() => import('./../src/Page/TransactionHistory/WithdrawBTC'));
const WithdrawUSDT = lazy(() => import('./../src/Page/TransactionHistory/WithdrawUSDT'));
const Branch = lazy(() => import('./../src/Page/Branch'));
const Notifications = lazy(() => import('./../src/Page/Notifications'));
const Organization = lazy(() => import('./../src/Page/Organization'));
const LeaderBoard = lazy(() => import('./../src/Page/LeaderBoard'));
const Packet = lazy(() => import("./../src/Page/Packet"));
const Recharge = lazy(() => import("./../src/Page/Recharge"));
const Withdraw = lazy(() => import("./../src/Page/Withdraw"));
const WithdrawInfo = lazy(() => import("./../src/Page/WithdrawInfo"));
const Support = lazy(() => import("./../src/Page/Support"));
const Profile = lazy(() => import("./../src/Page/Profile"));
const ProfilePolicy = lazy(() => import("./../src/Page/Profile/policy"));
const ProfileIntroOverview= lazy(() => import("./../src/Page/Profile/introOverview"));
const ProfileCustomer = lazy(() => import("./../src/Page/Profile/customer"));
const ProfileAboutUs = lazy(() => import("./../src/Page/Profile/aboutUs"));
const InfoProfile = lazy(() => import("./../src/Page/Profile/infoProfile"));
const ProfileGuideUser = lazy(() => import("./../src/Page/Profile/guideUser"));
const BetRecordsHistory = lazy(() => import("./../src/Page/Profile/betRecordsHistory"));
const Management = lazy(() => import("./Page/Management"));
const Police = lazy(() => import("./../src/Page/Police"));
const ListPackageBonus = lazy(() => import("./../src/Page/ListPackageBonus"));
const Staking = lazy(() => import("./Page/Staking"));
const GameWindGo = lazy(() => import("./Page/GameWinGo"));
const GameK3Lotre = lazy(() => import("./Page/GameK3Lotre"));
const PacketVersion2 = lazy(() => import("./Page/Packet/index_v2"));
const PacketMyTeam = lazy(() => import("./Page/Packet/indexPacketMyTeam"));
const DetailCommission = lazy(() => import("./Page/Packet/indexPacketMyTeam"));

export const routes = {
  login: {
    path: "/login",
    component: Login,
    isAuth: false,
    label: "Đăng nhập",
  },
  register: {
    path: "/register",
    component: Register,
    isAuth: false,
    label: "Đăng ký",
  },
  forgotPass: {
    path: "/forgot",
    component: ForgotPass,
    isAuth: false,
    label: "Quên mật khẩu"
  },
  police: {
    path: "/police",
    component: Police,
    isAuth: false,
    label: "Chính sách bảo mật",
  },
  allBetWingo: {
    path: "/game-all-win-go",
    component: AllBetWingo,
    isAuth: false,
    label: "Mega 5D",
    isHiddenFooter: true,
  },
  allBet5DLotre: {
    path: "/game-all-5d-lotre",
    component: AllBet5DLotre,
    isAuth: false,
    label: "Mega 5D",
    isHiddenFooter: true,
  },
  allBetK3Lotre: {
    path: "/game-all-k3-lotre",
    component: AllBetK3Lotre,
    isAuth: false,
    label: "Max K3",
    isHiddenFooter: true,
  },
  game5DLotre: {
    path: "/game-5d-lotre",
    component: Game5DLotre,
    isAuth: false,
    label: "Mega 5D",
    isHiddenFooter: true,
  },
  introduce: {
    path: "/introduce",
    component: Introduce,
    isAuth: false,
    label: "Giới thiệu",
    isTop: true,
    showFooterMobile: true
  },
  promotion: {
    path: "/promotion",
    component: Promotion,
    isAuth: false,
    label: "Quảng bá đại lý",
    isTop: true,
    showFooterMobile: true
  },
  historyPromotion: {
    path: "/promotionDes",
    component: HistoryPromotion,
    isAuth: false,
    label: "Lịch sử nhận",
    isTop: true,
    showFooterMobile: true
  },
  myTeam: {
    path: "/myTeam",
    component: MyTeam,
    isAuth: false,
    label: "Đội của tôi",
    isTop: true,
    showFooterMobile: true
  },
  tuturial: {
    path: "/tuturial",
    component: Tuturial,
    isAuth: false,
    label: "Hướng dẫn",
  },
  introdure: {
    path: "/leader-board",
    component: LeaderBoard,
    isAuth: false,
    label: "Giải thưởng",
    isTop: true
  },
  managementWallet: {
    path: "/management/wallet",
    component: Management,
    isAuth: false,
    label: "Danh sách ví",
    isMenuItem: true,
    icon: <IconWallet />,
    className: "cosutmSelect"
  },
  managementPacket: {
    path: "/management/packet",
    component: PacketVersion2,
    isAuth: false,
    label: "Quảng bá đại lý",
    isMenuItem: true,
    icon: <IconPacket />,
    showFooterMobile: true
  },
    managementPacketMyTeam: {
    path: "/management/PacketMyTeam",
    component: PacketMyTeam,
    isAuth: false,
    label: "Dữ liệu cấp dưới",
    isMenuItem: true,
    icon: <IconPacket />,
    showFooterMobile: true
  },
    managementPacketDetailCommission: {
    path: "/management/DetailCommission",
    component: DetailCommission,
    isAuth: false,
    label: "Chi tiết hoa hồng",
    isMenuItem: true,
    icon: <IconPacket />,
    showFooterMobile: true
  },
  managementPackageBonus: {
    path: "/management/package-notification",
    component: ListPackageBonus,
    isAuth: false,
    label: "Nhà máy",
    isMenuItem: true,
    icon: <ThunderButton2 />,
    showFooterMobile: true
  },
  managementRechargeHistory: {
    path: "/management/transaction-history",
    component: TransactionHistory,
    isAuth: false,
    label: "Lịch sử ",
    mobileTitle: "Lịch sử giao dịch",
    isMenuItem: true,
    icon: <IconRechargeHistory />
  },
  managementDepositHistory: {
    path: "/management/deposit-history",
    component: DepositUSDT,
    isAuth: true,
    label: "Nạp USDT",
    isSubItem: true,
  },
  recharge: {
    path: "/Recharge",
    component: Recharge,
    isAuth: false,
    label: "Nạp Tiền",
    isSubItem: true,
  },
  withdrawInfo: {
    path: "/withdraw-info",
    component: WithdrawInfo,
    isAuth: false,
    label: "Thông tin rút tiền",
    // isSubItem: true,
  },
  managementDeposit: {
    path: "/Withdraw",
    component: Withdraw,
    isAuth: false,
    label: "Rút Tiền",
    // isSubItem: true,
  },
 
  
  managementWithdrawHistoryUSDT: {
    path: "/management/withdraw-history-usdt",
    component: WithdrawUSDT,
    isAuth: false,
    label: "Rút USDT",
    isSubItem: true,
  },
  managementViewHistoryFAC: {
    path: "/management/view-history-fac",
    component: ReceiveFAC,
    isAuth: false,
    label: "Nhận FAC",
    isSubItem: true,
  },
  managementUserExchangeFACHistory: {
    path: "/management/user-exchange-fac-history",
    component: ExchangeFAC,
    isAuth: false,
    label: "Quy đổi FAC",
    isSubItem: true,
  },
  managementUserReceivePOINTHistory: {
    path: "/management/user-receive-point-history",
    component: ReceivePoint,
    isAuth: false,
    label: "Nhận hoa hồng",
    isSubItem: true,
  },
  managementUserExchangePOINTHistory: {
    path: "/management/user-exchange-point-history",
    component: ExchangePoint,
    isAuth: false,
    label: "Đổi hoa hồng",
    isSubItem: true,
  },
  managementViewHistoryBTC: {
    path: "/management/view-history-btc",
    component: ReceiveBTC,
    isAuth: false,
    label: "Nhận BTC",
    isSubItem: true,
  },
  managementWithdrawHistory: {
    path: "/management/withdraw-history",
    component: WithdrawBTC,
    isAuth: false,
    label: "Rút BTC",
    isSubItem: true,
  },
  managementHistoryServicePackage: {
    path: "/management/history-service-package",
    component: BoughtMachine,
    isAuth: false,
    label: "Mua máy đào",
    isSubItem: true,
  },
  branch: {
    path: "/management/branch",
    component: Branch,
    isAuth: false,
    label: <FormattedMessage id="branch" />,
    isMenuItem: true,
    icon: <IconBranch />,
    showFooterMobile: true
  },
  organization: {
    path: "/management/organization",
    component: Organization,
    isAuth: false,
    label: <FormattedMessage id="organization" />,
    isMenuItem: true,
    icon: <IconOrganizationSmall />
  },
  notification: {
    path: "/management/notification",
    component: Notifications,
    isAuth: false,
    label: <FormattedMessage id="notification" />,
    isMenuItem: true,
    icon: <IconNotification />
  },
  notificationDetail: {
    path: "/management/notification/:id",
    component: NotificationDetail,
    isAuth: false,
    isMenuItem: true,
    isHidden: true,
    label: <FormattedMessage id="notification" />,
  },
  leaderBoard: {
    path: "/management/leader-board",
    component: LeaderBoard,
    isAuth: false,
    label: <FormattedMessage id="leader_board" />,
    isMenuItem: true,
    icon: <IconLeaderBoard />
  },
  saving: {
    path: "/management/saving",
    component: Saving,
    isAuth: false,
    label: <FormattedMessage id="saving" />,
    isMenuItem: true,
    icon: <IconSaving />
  },
  managementBetRecordsHistory: {
    path: "/management/bet-record-profile",
    component: BetRecordsHistory,
    isAuth: false,
    label: "Lịch sử mua",
    isMenuItem: false,
    icon: <IconProfileIcon />
  },
  managementInfoProfile: {
    path: "/management/info-profile",
    component: InfoProfile,
    isAuth: false,
    label: "Tài khoản",
    isMenuItem: false,
    icon: <IconProfileIcon />
  },
  managementProfileAboutUs: {
    path: "/management/profile-about-us",
    component: ProfileAboutUs,
    isAuth: false,
    label: "Về chúng tôi",
    isMenuItem: false,
    icon: <IconProfileIcon />
  },
  managementProfileIntroOverview: {
    path: "/management/profile-intro-overview",
    component: ProfileIntroOverview,
    isAuth: false,
    label: "Thảo luận tiết lộ rủi ro",
    isMenuItem: false,
    icon: <IconProfileIcon />
  },
  managementProfilePolicy: {
    path: "/management/profile-policy",
    component: ProfilePolicy,
    isAuth: false,
    label: "Chính sách bảo mật",
    isMenuItem: false,
    icon: <IconProfileIcon />
  },
  managementProfileCustomer: {
    path: "/management/profile-customer",
    component: ProfileCustomer,
    isAuth: false,
    label: "Khách hàng",
    isMenuItem: false,
    icon: <IconProfileIcon />
  },
  managementProfileGuideUser: {
    path: "/management/profile-guide-user",
    component: ProfileGuideUser,
    isAuth: false,
    label: "Khách hàng",
    isMenuItem: false,
    icon: <IconProfileIcon />
  },
  managementProfile: {
    path: "/management/profile",
    component: Profile,
    isAuth: false,
    label: "Tài khoản",
    isMenuItem: true,
    icon: <IconProfileIcon />
  },
  gameWinGo: {
    path: "/gameWinGo",
    component: GameWindGo,
    isAuth: false,
    label: "Power WINGO",
    isHiddenFooter: true,
  },
  gameK3Lotre: {
    path: "/gameK3Lotre",
    component: GameK3Lotre,
    isAuth: false,
    label: "Max K3",
    isHiddenFooter: true,
  },
  settings: {
    path: "/settings",
    component: Settings,
    isAuth: false,
    label: "Cài đặt",
    isMenuItem: true,
    icon: <IconSettings />
  },
  support: {
    path: "/management/support",
    component: Support,
    isAuth: false,
    label: "Hỗ trợ",
    isMenuItem: true,
    icon: <IconSupport />
  },
  management: {
    path: "/management",
    component: Management,
    isAuth: false,
    isTop: true,
    label: "Quản lý",
    isMenuItem: true,
  },
  signout: {
    path: "/signout",
    label: "Đăng xuất",
    icon: <IconSignout />,
    isAuth: false,
  },
  contact: {
    path: "/contact",
    component: Support,
    isAuth: false,
    label: "Liên hệ",
    isMobileHidden: true,
    isTop: true
  },
  detailBranch: {
    path: "/detail-branch",
    component: DetailBranch,
    isAuth: false,
  },
  staking: {
    path: '/management/staking',
    isAuth: false,
    component: Staking
  }
}

function App() {
  const isUserLoggedIn = useSelector(state => state.member ? state.member.isUserLoggedIn : false);
  useEffect(() => {
    const THEME = process.env.REACT_APP_THEME || 'VESOVN'
    document.body.setAttribute('data-theme', THEME)
  }, [])
  routes.home = {
    path: "/",
    component: Home,
    isAuth: false,
    label: "Trang chủ",
  }

  return (
    <Router>
      <Suspense fallback={<div><FormattedMessage id="LOADING_TEXT" defaultMessage={'Đang tải dữ liệu...'} /></div>}>
        <Switch>
          {Object.keys(routes).map((key, index) => {
            if (isUserLoggedIn && routes[key].isAuth) {
      
              return (
                <Route
                  key={index}
                  exact
                  path={routes[key].path}
                  component={(props) => (
                    <Layout
                      {...props}
                      routes={routes}
                      isAuth={routes[key].isAuth}
                      isMenuItem={routes[key].isMenuItem}
                      isSubMenu={routes[key].isSubMenu}
                      isSubItem={routes[key].isSubItem}
                      Component={routes[key].component}
                      showFooterMobile={routes[key].showFooterMobile}
                      isHiddenFooter={routes[key].isHiddenFooter}
                      className={`${routes[key].className || 'HOME'}`} />
                  )} />
              );
            } else if (!routes[key].isAuth) {

              return (
                <Route
                  key={index}
                  exact
                  path={routes[key].path}
                  component={(props) => (
                    <Layout
                      {...props}
                      routes={routes}
                      isAuth={routes[key].isAuth}
                      Component={routes[key].component}
                      showFooterMobile={routes[key].showFooterMobile}
                      className={routes[key].isHeader ? "HOME" : "LOGIN"} 
                      isHiddenFooter={routes[key].isHiddenFooter}
                      />
                  )} />
              );
            }
          })}
        </Switch>
      </Suspense>
    </Router>
  );
}

export default App;
