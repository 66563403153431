import classNames from 'classnames'
import { useModal } from 'context/ModalContext'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { connect, useDispatch } from 'react-redux'
import { routes } from './../../App'
import { Image } from 'antd'
import { useSelector } from 'react-redux'
import './index.scss'
import { IconHome2, IconWallet2, IconNotification2, IconProfile2, IconDiamon2 } from './../../assets/icons/index'
import GameInfoService from 'services/gameInfoService'
import { setGameInfo } from 'actions/systemActions'
import SystemConfiguration from 'services/systemConfiguration'
import { setSystemConfig } from 'actions/systemActions'

function LayoutPage(props) {
  const { Component, className = '', isHiddenFooter } = props
  const isUserLoggedIn = useSelector((state) => (state.member ? state.member.isUserLoggedIn : false))
  const { memberReferIdF1 } = useSelector((state) => state.member || {})
  const { location, history } = props
  const { pathname = '' } = location
  const modal = useModal()
  const dispatch = useDispatch()
  const dispatchData = useCallback(
    (action) => {
      dispatch(action)
    },
    [dispatch]
  )

  const intl = useIntl()
  const t = useCallback((id) => intl.formatMessage({ id }), [intl])

  const isHome = useMemo(() => {
    return pathname === routes?.home?.path
  }, [pathname])

  const isFactory = useMemo(() => {
    return pathname === routes?.managementPackageBonus?.path
  }, [pathname])

  const isStore = useMemo(() => {
    return pathname === routes?.managementPacket?.path
  }, [pathname])

  const isBranch = useMemo(() => {
    return pathname === routes?.branch?.path
  }, [pathname])

  const isProfile = useMemo(() => {
    return pathname === routes?.managementProfile?.path
  }, [pathname])

  function handleClickFooterIcon(path) {
    history.push(path)
    modal.hide()
  }

  useEffect(() => {
    window.addEventListener('scroll', (e) => {
      const headerId = document.getElementById('header-sticky')
      if (headerId && headerId.classList) {
        if (window.pageYOffset > 0) {
          headerId.classList.toggle('sticky')
        } else {
          headerId.classList.remove('sticky')
        }
      }
    })
  }, [])

  useEffect(() => {
    if (props.isAuth) {
      // document.getElementById('root').style.height = 'calc(100vh - 120px)'
    } else {
      document.getElementById('root').style.height = '100vh'
    }
  }, [props, props.isAuth])

  useEffect(() => {
    const bodyId = document.getElementById('body-root')
    if (bodyId) {
      // bodyId.className = "bg-gray"
      bodyId.classList.add(className || '')
    }
  }, [className])

  const renderTabIcons = (routename) => {
    switch (routename) {
      case 'home':
        if (isHome) {
          return <IconHome2 className="active" height={25} width={25} />
        } else return <IconHome2 height={25} width={25} />
      case 'attendance':
        if (isFactory) {
          return <IconNotification2 className="active" height={25} width={25} />
        } else return <IconNotification2 height={25} width={25} />

      case 'marketing':
        return <IconDiamon2 height={30} width={30} />
      case 'wallet':
        if (isBranch) {
          return <IconWallet2 className="active" height={25} width={25} />
        } else return <IconWallet2 height={25} width={25} />

      case 'user':
        if (isProfile) {
          return <IconProfile2 className="active" height={25} width={25} />
        } else return <IconProfile2 height={25} width={25} />
      default:
        break
    }
  }

  useEffect(() => {
    getListGameInfo()
    getSystemConfig()
  }, [])
  function getListGameInfo() {
    GameInfoService.getListGameInfo({}).then((result) => {
      const { isSuccess, data } = result
      if (isSuccess) {
        if (data?.data && data.data) {
          dispatchData(setGameInfo(data.data))
        }
      }
    })
  }
  function getSystemConfig() {
    SystemConfiguration.systemConfigurationGetDetail({}).then((result) => {
      const { isSuccess, data } = result
      if (isSuccess) {
        if (data && data) {
          dispatchData(setSystemConfig(data))
        }
      }
    })
  }
  return (
    <>
      <div style={{ marginBottom: 90 }}>
        <Component {...props} />
      </div>
      {!isHiddenFooter ? (
        <footer className="d-block bg-white p-0 fixed-bottom footer__mobile">
          <div className="d-flex justify-content-between footer__item py-2 px-1 navigation text-center" style={{ height: '80px' }}>
            <div
              className={classNames('footer__item py-2', { 'border-bottom-active': isHome, 'text-primary': isHome })}
              role="button"
              onClick={() => handleClickFooterIcon(routes?.home?.path)}>
              {renderTabIcons('home')}
              {/* {isHome ? <HomeFilled className="fs-5" /> : <HomeOutlined className="fs-5" />} */}
              <p className={classNames('fs-7 mt-1', { navigation_active: isHome })}>{t('homepage')}</p>
            </div>
            <div
              className={classNames('footer__item py-2', { 'border-bottom-active': isFactory, 'text-primary': isFactory })}
              role="button"
              onClick={() => handleClickFooterIcon(isUserLoggedIn ? routes?.notification?.path : routes?.login?.path)}>
              {renderTabIcons('attendance')}
              <p className={classNames('fs-7 mt-1', { navigation_active: isFactory })}>{t('attendance')}</p>
            </div>
            {memberReferIdF1 === 1 || memberReferIdF1 === null ? (
              <div
                className={classNames('relative', { 'text-primary': isStore })}
                role="button"
                onClick={() => handleClickFooterIcon(isUserLoggedIn ? routes?.managementPacket?.path : routes?.login?.path)}>
                <div className="muan">{renderTabIcons('marketing')}</div>
                <div className="centertxt">
                  <p className="fs-7">{t('marketing')}</p>
                </div>
              </div>
            ) : null}

            <div
              className={classNames('footer__item py-2', { 'border-bottom-active': isBranch, 'text-primary': isBranch })}
              role="button"
              onClick={() => handleClickFooterIcon(isUserLoggedIn ? routes?.branch?.path : routes?.login?.path)}>
              {renderTabIcons('wallet')}
              <p className={classNames('fs-7 mt-1', { navigation_active: isBranch })}>{t('wallet')}</p>
            </div>
            <div
              className={classNames('footer__item py-2', { 'border-bottom-active': isProfile, 'text-primary': isProfile })}
              role="button"
              onClick={() => handleClickFooterIcon(isUserLoggedIn ? routes?.managementProfile?.path : routes?.login?.path)}>
              {renderTabIcons('user')}
              <p className={classNames('fs-7 mt-1', { navigation_active: isProfile })}>{t('userProfile')}</p>
            </div>
          </div>
        </footer>
      ) : null}
    </>
  )
}

const mapStateToProps = (state) => ({
  member: state.member || {}
})

const mapDispatchToProps = (dispatch) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(LayoutPage)
