export const setGameInfo = (gameInfo) => ({
  type: 'GAME_INFO',
  payload: {
    gameInfo
  }
})
export const setSystemConfig = (systemConfig) => ({
  type: 'SYSTEM_CONFIG',
  payload: {
    systemConfig
  }
})
