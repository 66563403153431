const initialState = {
  gameInfo: {}
}

export default function systemReducer(state = initialState, action) {
  const data = action.payload
  switch (action.type) {
    case 'GAME_INFO':
      return {
        ...state,
        ...data
      }
    case 'SYSTEM_CONFIG':
      return {
        ...state,
        ...data
      }
    default:
      return state
  }
}
