import React, { useState, useEffect } from 'react'
import { Form, notification, Button } from 'antd'
import "./forgotPass.scss";
import Header from '../../components/Header';
import LoginService from '../../services/loginService'
import { injectIntl } from 'react-intl'
import { routes } from "./../../App"
import SystemConfiguration from '../../services/systemConfiguration'
import ChatBox from 'components/ChatBox';
import ChangeLanguage from 'components/ChangeLanguage';

function ForgotPass(props) {
  const [form] = Form.useForm()
  const { history, intl } = props
  const [system, setsystem] = useState({})

  useEffect(() => {
    SystemConfiguration.systemConfigurationGetDetail().then((res)=>{
      const {data, isSuccess} = res
        if(isSuccess){
          setsystem(data)
        }
      }
    )

  }, [])
  return (
    <div className="">
      <ChatBox />
      <Header
        goBack={() => {
          history.push(routes.login.path)
        }}
        title={intl.formatMessage({ id: 'forgot' })}
        headerRight={<ChangeLanguage />}
        >
          
        </Header>
      <div className="h-100 d-flex align-items-center flex-column mx-auto mt-3 text-center" style={{ color: 'var(--text_color_primary)' }}>
      {intl.formatMessage({ id: 'ForgotPassword.label1' })} <br />
      {intl.formatMessage({ id: 'ForgotPassword.label2' })}
      </div>
      <div className="mt-3 w-100 text-center">
        <Button
          onClick={() => {
            window.open(system?.telegramGroupUrl)
          }}
          className="mt-3 button-main">
          {intl.formatMessage({ id: 'ForgotPassword.label3' })}
        </Button>
      </div>
    </div>
  )
}

export default injectIntl(ForgotPass)
