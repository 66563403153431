import React, { useCallback } from 'react';
import { Tabs } from 'antd';
import RechargeHistory from './DepositUSDT';
import { useHistory } from "react-router-dom";
import WithDrawalHistory from './WithdrawUSDT';
import { useIntl } from 'react-intl';
import Header from '../../components/Header';
export default function TransactionHistory({ defaultActiveKey = "1" }) {
  const history = useHistory()
  const onChangeTab = () => {

  }
  const intl = useIntl();
  const translate = useCallback(
    (id) => {
      return intl.formatMessage({ id })
    },
    [intl]
  )
  return (
    <section className="management packet mb-0">
      <Header goBack={() => { history.goBack() }} title={translate('Branch.label2')} ></Header>

      <Tabs className=" profile__tab" defaultActiveKey={defaultActiveKey} onChange={onChangeTab}>
        <Tabs.TabPane tab={translate('Branch.label6')} key="1">
          <RechargeHistory />
        </Tabs.TabPane>
        <Tabs.TabPane tab={translate('Branch.label7')} key="2">
          <WithDrawalHistory />
        </Tabs.TabPane>

      </Tabs>

    </section>
  )
}