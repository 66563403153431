import { Select } from 'antd'
import React, { useContext } from 'react'
import './changeLanguage.scss'
import { IntlContext } from '../../helper/Internationalization'
import { CaretDownOutlined } from '@ant-design/icons'
import { LanguageEnglish } from 'assets/icons/languange/iconLanguage'
import { LanguageChinese } from 'assets/icons/languange/iconLanguage'
import { LanguageVietnamese } from 'assets/icons/languange/iconLanguage'
function ChangeLanguage({ className }) {
  const intlContext = useContext(IntlContext)

  const langObj = [
    {
      label: 'EN',
      flag: <LanguageEnglish className="flag" />,
      value: 'en'
    },
    {
      label: 'CN',
      flag: <LanguageChinese className="flag" />,
      value: 'cn'
    },
    {
      label: 'VI',
      flag: <LanguageVietnamese className="flag" />,
      value: 'vi'
    }
  ]

  // ** Function to switch Language
  const handleLangUpdate = (lang) => {
    intlContext.switchLanguage(lang)
  }

  return (
    <div className={`change_language ${className}`}>
      <Select
        value={intlContext.locale}
        onChange={handleLangUpdate}
        className="change_language-selected"
        size="large"
        suffixIcon={<CaretDownOutlined />}>
        {langObj.map((item) => {
          return (
            <Select.Option key={item.value}>
              {item.flag}
              <span className="text">{item.label.slice(0, 3)}</span>
            </Select.Option>
          )
        })}
      </Select>
    </div>
  )
}

export default ChangeLanguage
