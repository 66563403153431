import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';
import GameRecordsService from "services/gameRecordService";
import BetRecordsService from "services/betRecordsService";
import { useIntl } from 'react-intl';
import { notification } from 'antd';
import { setHistoryWingo, setBetHistory } from 'actions'
import { useDispatch, useSelector } from 'react-redux';

const HistoryContext = createContext(null);

// const DEFAULT_FILTER = {
//   filter: {
//     gameRecordType: "WINGO1"
//   },
//   skip: 0,
//   limit: 20,
// };
export function HistoryProvider({ children }) {
  const intl = useIntl();
  const history = useSelector(state => state.history);
  const t = useCallback(id => intl.formatMessage({ id }), [intl]);
  const dispatch = useDispatch();

  function gameHistory(values, callback) {
    GameRecordsService.getList(values).then((result) => {
      const { isSuccess, message, data } = result;
      if (!isSuccess || !data) {
        notification["error"]({
          message: "",
          description: message || t("something_wrong"),
        });
        return;
      } else {
        dispatch(setHistoryWingo(data))
      }
      if (callback && callback instanceof Function) {
        callback();
      }
    });
  }
  function betHistory(values, callback) {
    BetRecordsService.getList(values).then((result) => {
      const { isSuccess, message, data } = result;
      if (!isSuccess || !data) {
        notification["error"]({
          message: "",
          description: message || t("something_wrong"),
        });
        return;
      } else {
        dispatch(setBetHistory(data))
      }
      if (callback && callback instanceof Function) {
        callback();
      }
    });
  }
  return (
    <HistoryContext.Provider value={{ history, gameHistory, betHistory }}>
      {children}
    </HistoryContext.Provider>
  )
}

export function useGameHistory() {
  return useContext(HistoryContext);
}
