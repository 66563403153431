
import { HISTORY_WINGO, BET_HISTORY } from '../constants/history'
let initialState = {
  historyList: [],
  betHistoryList: [],
}
export default function userReducer(state = initialState, action) {

  switch (action.type) {
    case HISTORY_WINGO: {
      if (action.data) {
        return {
          ...state,
          historyList: action.data
        }
      }
      return {}
    }
    case BET_HISTORY: {
      if (action.data) {
        return {
          ...state,
          betHistoryList: action.data
        }
      }
      return {}
    }
    default:
      return state
  }
}

